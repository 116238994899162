.@{ant-prefix}-table-thead > tr > th {
  height: 56px;
  padding-left: 21px !important;
  background: #f5f7fa;

  &::before {
    display: none;
  }
}

table tr th.@{ant-prefix}-table-selection-column,
table tr td.@{ant-prefix}-table-selection-column {
  padding-left: 8px !important;
}

.@{ant-prefix}-table.@{ant-prefix}-table-middle .@{ant-prefix}-table-tbody > tr > td {
  height: 56px;
  padding-left: 21px !important;
}

.@{ant-prefix}-tabs-tab + .@{ant-prefix}-tabs-tab {
  margin: 0;
}

.@{ant-prefix}-tabs-tab .@{ant-prefix}-tabs-tab-btn {
  padding: 0 25px;
}

.default_tabs {
  .@{ant-prefix}-tabs-nav {
    padding: 0 20px;
  }

  .@{ant-prefix}-tabs-tab .@{ant-prefix}-tabs-tab-btn {
    padding: 0;
  }

  .@{ant-prefix}-tabs-tab + .@{ant-prefix}-tabs-tab {
    margin: 0 0 0 20px;
  }
}

.@{ant-prefix}-table-tbody > tr > td {
  padding-left: 21px !important;
}

.modal_table {
  .@{ant-prefix}-table {
    min-height: 331px;
  }
}

.form_all_required {
  .@{ant-prefix}-form-item-required {
    &::before {
      display: none !important;
    }
  }
}

.@{ant-prefix}-card-body {
  padding: 20px;
}

.@{ant-prefix}-modal-confirm-body > .@{ant-prefix}icon + .@{ant-prefix}-modal-confirm-title + .@{ant-prefix}-modal-confirm-content {
  margin-left: 0;
}

.@{ant-prefix}-modal-confirm-body {
  margin-top: -32px;
}

.@{ant-prefix}-modal-confirm-body > .anticon {
  height: 54px;
  line-height: 58px !important;
}

.@{ant-prefix}-modal-confirm-body .@{ant-prefix}-modal-confirm-title {
  height: 54px;
  line-height: 54px !important;
}

.@{ant-prefix}-modal-confirm .@{ant-prefix}-modal-body {
  position: relative;
  padding: 32px 20px 20px 20px;

  &::before {
    position: absolute;
    top: 54px;
    left: 0;
    width: 416px;
    height: 1px;
    background: #f5f7fa;
    content: '';
  }
}

.@{ant-prefix}-dropdown-menu-item,
.@{ant-prefix}-dropdown-menu-submenu-title {
  &:hover {
    color: #fff;
    background: @primary-color;
  }
}

.@{ant-prefix}-dropdown-menu {
  padding: 0;
}

.@{ant-prefix}-breadcrumb ol {
  margin: 0;
}

.@{ant-prefix}-table-expanded-row-level-1 {
  .@{ant-prefix}-table-thead {
    display: none !important;
  }
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.@{ant-prefix}-tree-title .auto_action_wapper {
  display: none;
}
.@{ant-prefix}-badge-multiple-words {
  padding: 0 4px;
}

@ant-prefix: ly;@primary-color: #386CF6;@card-shadow: 0 1px 2px -2px #00000029,   0 3px 6px #0000001f,0 5px 12px 4px #00000017;