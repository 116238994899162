body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #eaeff7;
  border-radius: 8px;
  background-clip: content-box;
  border: 2px solid transparent;
}

body[scroll],
::-webkit-scrollbar-thumb:hover {
  transition: 0s;
  background-color: rgba;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.icon {
  width: 1em;
  height: 1em;
  overflow: hidden;
  vertical-align: -0.15em;
  fill: currentColor;
}

.dropdown-custom {
  .@{ant-prefix}-dropdown-arrow {
    display: none;
  }

  .@{ant-prefix}-dropdown-menu {
    margin-top: -18px;
  }
}

.item-address-drag {
  padding-bottom: 10px;

  &:last-child {
    padding-bottom: 0;

    .@{ant-prefix}-timeline-item-tail {
      display: none;
    }
  }

  .item-box-address-drag {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid #eaeff7;

    .text-box {
      display: block !important;
      width: 295px !important;
      max-width: 295px !important;

      .text-label {
        display: inline-block;
        margin-right: 5px;
        padding: 0 2px;
        line-height: 18px;
        border-radius: 1px;
      }

      .text-label-add {
        color: #0145ff;
        border: 1px solid #0145ff;
      }

      .text-label-remove {
        color: #ffc120;
        border: 1px solid #ffc120;
      }
    }

    .icon-box {
      display: flex;
      justify-content: flex-start;
      padding-left: 20px;

      span {
        margin-right: 6px;
        padding: 5px;
        font-size: 16px;
        cursor: pointer;

        &:hover {
          color: #386cf6;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.dot-address-drag {
  width: 20px;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
}

.dot-address-drag-start {
  color: #386cf6;
  background: rgba(56, 192, 252, 0.2);
}

.dot-address-drag-middle {
  color: #38c0fc;
  background: rgba(56, 192, 252, 0.2);
}

.dot-address-drag-end {
  color: #01b466;
  background: rgba(1, 180, 102, 0.2);
}

.amap-marker-label {
  background-color: transparent;
  border: 0;
}

.map-car-box {
  position: relative;
  width: 93px;
  height: 30px;
  font-size: 13px;
  line-height: 30px;
  text-align: center;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  &::after {
    position: absolute;
    right: 40px;
    bottom: -16px;
    width: 0;
    height: 0;
    margin: 0 auto;
    border-top: 8px solid #fff;
    border-right: 5px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 5px solid transparent;
    content: ' ';
  }
}

.map-car-box-text {
  position: relative;
  width: 150px;
  height: 26px;
  font-size: 12px;
  line-height: 26px;
  text-align: center;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  &::after {
    position: absolute;
    right: 70px;
    bottom: -12px;
    width: 0;
    height: 0;
    margin: 0 auto;
    border-top: 6px solid #fff;
    border-right: 4px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 4px solid transparent;
    content: ' ';
  }
}

.@{ant-prefix}-input-affix-wrapper-textarea-with-clear-btn {
  textarea {
    padding-right: 20px;
  }
}

.item-label-require-show-none {
  .@{ant-prefix}-form-item-required::before {
    display: none !important;
  }
}

.@{ant-prefix}-input-affix-wrapper-readonly {
  border: 1px solid #d9d9d9 !important;
  box-shadow: none !important;
}

.item-label-require {
  .@{ant-prefix}-form-item-label {
    label {
      &::before {
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
      }
    }
  }
}

.FullSCreenModal {
  &.back {
    .@{ant-prefix}-modal-content .@{ant-prefix}-modal-body {
      background: #f0f2f5;
    }
  }

  width: 100vw !important;
  max-width: 100vw !important;
  top: 0 !important;
  padding: 0 !important;

  .@{ant-prefix}-modal-header {
    .@{ant-prefix}-modal-title {
      color: #324458 !important;
      font-size: 16px !important;
      line-height: 27px !important;
    }
  }

  .@{ant-prefix}-modal-content {
    height: 100vh !important;

    .@{ant-prefix}-modal-body {
      height: calc(100% - 60px) !important;
      padding: 20px 53px 20px 54px !important;
      overflow-y: auto !important;
    }
  }
}

.success-btn {
  color: #fff !important;
  background: #52c41a !important;
  border-color: #52c41a !important;
}

.@{ant-prefix}-table tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff !important;
}

.@{ant-prefix}-table tr.drop-over-upward td {
  border-top: 2px dashed #1890ff !important;
}

.@{ant-prefix}-image-preview-operations {
  background: transparent;
}

.table-box-user-policy {
  width: 100%;
  overflow-x: auto;
}

.autoColumns {
  .anticon.anticon-setting {
    display: none;
  }
}
.error-box-show {
  .@{ant-prefix}-modal-confirm-btns {
    display: none;
  }
  .@{ant-prefix}-modal-confirm {
    .@{ant-prefix}-modal-body::before {
      display: none;
    }
  }
  .@{ant-prefix}-modal-confirm-body {
    .anticon-info-circle {
      display: none;
    }
  }
  .@{ant-prefix}-modal-confirm-content {
    margin: 0 10px !important;
  }
}
.@{ant-prefix}-table {
  .@{ant-prefix}-table-tbody {
    tr {
      &.@{ant-prefix}-table-row {
        &:hover {
          td {
            background: #f8f8f8;
          }
        }
      }
      td {
        &.@{ant-prefix}-table-cell-row-hover {
          background: #f8f8f8;
        }
      }
    }
  }
}
.@{ant-prefix}-carousel {
  .slick-slider {
    .slick-prev {
      z-index: 2;
      height: 36px;
      width: 36px;
      cursor: pointer;
      opacity: 0;
      color: #fff !important;
      background-color: rgba(31, 45, 61, 0.11);
      transition: all 0.3s;
      left: 16px;
      border-radius: 50%;
      transform: translateY(-50%);
      &::before {
        font-size: 18px;
        line-height: 36px;
      }
      &.slick-disabled {
        opacity: 0 !important;
      }
      &:hover {
        background-color: rgba(31, 45, 61, 0.23);
        left: 10px;
      }
    }
    .slick-next {
      z-index: 2;
      height: 36px;
      width: 36px;
      cursor: pointer;
      opacity: 0;
      color: #fff !important;
      transition: all 0.3s;
      right: 16px;
      border-radius: 50%;
      background-color: rgba(31, 45, 61, 0.11);
      transform: translateY(-50%);
      &::before {
        font-size: 18px;
        line-height: 36px;
      }
      &.slick-disabled {
        opacity: 0 !important;
      }
      &:hover {
        background-color: rgba(31, 45, 61, 0.23);
        right: 10px;
      }
    }
  }
  &:hover {
    .slick-slider {
      .slick-prev {
        opacity: 1;
      }
      .slick-next {
        opacity: 1;
      }
    }
  }
}
.question-knowledge-detail-type {
  .@{ant-prefix}-descriptions-item-label {
    color: #606266;
    &::before {
      content: '';
      width: 20px;
      height: 14px;
      background-image: url(~@/assets/images/icon1.png);
      background-repeat: no-repeat;
      background-size: 60%;
      position: relative;
      top: 3px;
    }
  }
  .@{ant-prefix}-descriptions-item-content {
    color: #606266;
  }
}
.question-knowledge-detail-time {
  .@{ant-prefix}-descriptions-item-label {
    color: #606266;
    &::before {
      content: '';
      width: 20px;
      height: 14px;
      background-image: url(~@/assets/images/icon3.png);
      background-repeat: no-repeat;
      background-size: 60%;
      position: relative;
      top: 3px;
    }
  }
  .@{ant-prefix}-descriptions-item-content {
    color: #606266;
  }
}
.question-knowledge-detail-title {
  .@{ant-prefix}-descriptions-item-label {
    color: #606266;
    &::before {
      content: '';
      width: 20px;
      height: 14px;
      background-image: url(~@/assets/images/icon5.png);
      background-repeat: no-repeat;
      background-size: 60%;
      position: relative;
      top: 3px;
    }
  }
  .@{ant-prefix}-descriptions-item-content {
    color: #606266;
  }
}
.question-knowledge-detail-user {
  .@{ant-prefix}-descriptions-item-label {
    color: #606266;
    &::before {
      content: '';
      width: 20px;
      height: 14px;
      background-image: url(~@/assets/images/icon2.png);
      background-repeat: no-repeat;
      background-size: 60%;
      position: relative;
      top: 3px;
    }
  }
  .@{ant-prefix}-descriptions-item-content {
    color: #606266;
  }
}
.relation-box {
    .kx_UploadImg {
      .@{ant-prefix}-form-item-label{
        width: 100%;
        text-align: left;
        margin-left: 27px;
        margin-bottom: 5px;
        font-weight: bold;
      }
      .@{ant-prefix}-form-item-control-input-content{
        width: 100%;
        text-align: left;
        margin-left: 27px;
      }
    }
  }
  .model-info-footer-none {
    .@{ant-prefix}-modal-confirm-btns {
        display: none;
    }
    .@{ant-prefix}-modal-body{
    padding-bottom: 0 !important;
    }
  }

@ant-prefix: ly;@primary-color: #386CF6;@card-shadow: 0 1px 2px -2px #00000029,   0 3px 6px #0000001f,0 5px 12px 4px #00000017;