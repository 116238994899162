.flex {
  display: flex;

  .flex1 {
    flex: 1;
  }
}

.kx_phone {
  display: flex;
  align-items: center;
  height: 44px;
  overflow: hidden;
  border: 1px solid #eaeff7;
  border-radius: 2px;
  transition: 0.5s;

  &.error {
    border-color: #da4244;

    .@{ant-prefix}-input {
      background-color: rgba(36, 124, 255, 0.05);
    }
  }

  &:hover {
    border-color: #4d9aff;
  }

  .@{ant-prefix}-select-focused:not(.@{ant-prefix}-select-disabled).@{ant-prefix}-select:not(.@{ant-prefix}-select-customize-input) .@{ant-prefix}-select-selector {
    border-color: transparent;
    box-shadow: none;
  }

  .@{ant-prefix}-input {
    height: 44px !important;
    line-height: 44px;
  }

  .@{ant-prefix}-input:focus {
    box-shadow: none;
  }

  .@{ant-prefix}-select:not(.@{ant-prefix}-select-customize-input) .@{ant-prefix}-select-selector {
    border: none;
  }

  .@{ant-prefix}-select,
  .@{ant-prefix}-select-selector {
    border: none;
  }
}

.phoneIsError {
  height: 20px;
  margin-top: 5px;
  color: #da4244;
  font-weight: 400;
  font-size: 14px;
  font-family: PingFang SC;
  line-height: 20px;
}

.kx_code {
  height: 44px;
  border: 1px solid #eaeff7;

  &.error {
    border-color: #da4244;
  }

  .kx_code_btn {
    height: 32px;
    padding: 0 10px;
    color: #386cf6;
    font-weight: 400;
    font-size: 14px;
    font-family: PingFang SC;
    line-height: 32px;
    text-align: center;
    background-color: rgba(56, 108, 246, 0.1);
    border: none;
    border-radius: 2px;
    outline: none;
    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      background: #f5f5f5;
      text-shadow: none;
    }
  }
  &:focus {
    border-color: #eaeff7;
    box-shadow: none;
  }
}
.kx_code_min {
  height: 32px;
  .kx_code_btn {
    height: 22px;
    line-height: 22px;
    font-size: 12px;
  }
}
.kx_iconselect_btn {
  position: absolute;
  top: 0px;
  left: 0px;
  height: auto;
  width: 100%;
}

.kx_iconselect {
  width: 100%;
  max-height: 266px;
  height: auto;
  overflow: auto;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 2px 8px rgba(200, 201, 204, 0.5);
  opacity: 1;

  .kx_icon_wapper {
    display: flex;
    flex-wrap: wrap;
  }

  .iconfont {
    width: 20%;
    padding: 10px 20px;
    font-size: 26px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      color: @primary-color;
    }
  }
}

.kx_table_img {
  width: 30px;
}

.kx_tabs_box {
  overflow: hidden;
  border-bottom: 1px solid #eaeff7;

  .@{ant-prefix}-tabs {
    height: 100%;
    padding: 0 10px;
  }

  .@{ant-prefix}-tabs-nav {
    margin: 0;
    height: 100%;

    &::before {
      display: none;
    }
  }
}

.kx_table_Wapper {
  .@{ant-prefix}-table-thead .@{ant-prefix}-table-cell {
    height: 56px;
    background: #f5f7fa;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba(50, 68, 88, 0.8);

    &::before {
      display: none;
    }
  }

  .@{ant-prefix}-table-footer {
    padding: 0;
    background: #fff;
  }

  .@{ant-prefix}-table-tbody .@{ant-prefix}-table-placeholder {
    display: none !important;
  }
}

.@{ant-prefix}-input[readOnly]:not(.read-only-default) {
  border: none;
  outline: none;

  &:focus {
    box-shadow: none;
  }
}

.@{ant-prefix}-select[readOnly] {
  &.@{ant-prefix}-select-disabled {
    .@{ant-prefix}-select-selector {
      background: transparent;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.85);
    }

    .@{ant-prefix}-select-arrow {
      display: none;
    }
  }
}

.icon_input {
  .@{ant-prefix}-input {
    opacity: 0;
  }
}

.table_button_space {
  .@{ant-prefix}-btn {
    padding: 0;
    display: contents;
  }
}
.model-scroll-box {
  max-height: 400px;
  overflow: auto;
}
.row-title {
  background: #f5f7fa;
  line-height: 48px;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.input-info {
  border: none !important;
  background: transparent !important;
  color: rgba(0, 0, 0, 0.85) !important;
}
.@{ant-prefix}-table-content {
  .table-expandable-class {
    .@{ant-prefix}-table-thead {
      display: table-header-group !important;
      th {
        height: 40px;
        padding: 8px;
        background: rgba(0, 0, 0, 0.02);
      }
    }
    .@{ant-prefix}-table-tbody {
      td {
        height: 44px;
        padding: 10px;
      }
    }
  }
}
.base-list-item-meta-hide {
  .@{ant-prefix}-list-item {
    padding: 12px 20px;
    border-block-end: 1px solid rgba(5, 5, 5, 0.06) !important;
  }
  .@{ant-prefix}-list-item-meta-avatar {
    display: none !important;
  }
}
.form-search-custome {
  .@{ant-prefix}-form-item-control {
    position: relative;
    .@{ant-prefix}-form-item-explain-connected {
      position: absolute;
      top: 30px;
    }
  }
}

@ant-prefix: ly;@primary-color: #386CF6;@card-shadow: 0 1px 2px -2px #00000029,   0 3px 6px #0000001f,0 5px 12px 4px #00000017;