.modal_Form {
  .tips {
    height: 32px;
    margin-bottom: 10px;
    padding: 0 10px;
    color: #6ccd7e;
    font-size: 12px;
    font-family: PingFangSC-Regular;
    line-height: 32px;
    background: rgba(108, 205, 126, 0.11);
    border-radius: 2px;
    opacity: 1;
    position: relative;
  }

  .@{ant-prefix}-form-item {
    margin-bottom: 10px;
  }

  .modal_title_1 {
    margin-bottom: 10px;
  }

  .modal_title_2 {
    position: relative;
    margin-bottom: 10px;
    padding-left: 10px;
    color: #324458;

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 3px;
      height: 14px;
      margin: auto;
      background: @primary-color;
      border-radius: 2px;
      content: '';
    }
  }

  .modal_title {
    height: 40px;
    margin-bottom: 10px;
    padding: 0 10px;
    color: #324458;
    font-size: 14px;
    font-family: PingFangSC-Regular;
    line-height: 40px;
    background: #f5f7fa;
  }

  .modal_form_item {
    width: 100%;
  }

  .modal_text {
    padding: 0 10px;
    color: rgba(50, 68, 88, 0.6);
  }

  .moadl_formitem_wapper {
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  .modal_text_tips {
    color: rgba(50, 68, 88, 0.6);
    font-size: 12px;
  }
}
@ant-prefix: ly;@primary-color: #386CF6;@card-shadow: 0 1px 2px -2px #00000029,   0 3px 6px #0000001f,0 5px 12px 4px #00000017;